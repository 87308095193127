<template>
  <Header />
   <router-view v-slot="{ Component }" key='$route.fullPath'>
    <transition 
      name="route" 
      mode="out-in">
      <component :is="Component"></component>
    </transition>
  </router-view>
  <Footer />
</template>

<script>
import Header from './components/Header'
import Footer from './components/Footer'

export default {
  components: {
    Header,
    Footer,
  },
  watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                document.title = to.meta.title || 'TERRAVERA News';
            }
        },
    }
}
</script>

<style>
body {
    font-family: Nunito;
    font-style: normal;
    background: white;
    font-size: 18px;
}

html, body {
  margin: 0px;
  padding: 0px;
  scroll-snap-type: y;
  scroll-behavior: smooth;
}

[v-cloak] {
    display: none;
}

/* a:hover,
a.router-link-active {
  border-bottom: #c8fc9c solid 2px;
} */


/* router transitions  */

.route-enter-from {
  opacity: 0;
}

.route-enter-active {
  transition: opacity 0.3s ease-in;
}

.route-leave-to {
  opacity: 0;
}

.route-leave-active {
  transition: opacity 0.3s ease-out;
}


/* article styles  */
.content {
    margin-top: 5vh;
    width: 50vw;
    background: white;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    color: black;
}

h1 {
    font-weight: lighter;
    font-size: 50px;
    line-height: 50px;
    margin-top: 15vh;
}

#press_photo {
    width: 110%;
    transform: translateX(-5%);
}

.caption {
    margin-top: -10px;
    font-size: 14px;
}

.profile_section {
    display: flex;
    align-items: center;
    line-height: 20px;
    margin-top: 7vh;
}

.tvf_profile {
    display: flex;
}

.round_logo {
    min-width: 80px;
    min-height: 80px;
    width: 80px;
    height: 80px;
}

#symbol {
    width: 80%;
    margin-left: 10%;
    margin-top: 15%;
    border-radius: 50%;
}

.tvf_profile {
    width: 75vw;
}

.profile_info {
    display: flex;
    padding-left: 1vw;
    font-size: 14px;
    align-items: center;
    line-height: 20px;
}

#linkedin_icon {
    display: flex;
    justify-content: center;
    width: 35px;
    height: auto;
    margin-left: -moz-calc(5vw - 35px);
    margin-left: -webkit-calc(5vw - 35px);
    margin-left: -o-calc(5vw - 35px);
    margin-left: calc(5vw - 35px);
}

.content_text {
    margin-top: 7vh;
}

p {
    text-align: left; 
}

@media only screen and (max-width: 900px) {
  .content {
    width: 80vw;
  }
}
</style>
