<template>
  <div class="content" id="content" v-cloak>
    <div class="heading">
      <h1 id="heading">{{ article ? article.title : "" }}</h1>
    </div>
    <div class="sub_heading">
      <h4>{{ article ? article.intro : "" }}</h4>
    </div>
    <div class="img" v-show="article">
      <img
        v-if="imageExists(article.title)"
        :src="
          article ? require('@/assets/articles/' + article.title + '.png') : '#'
        "
        alt=""
        preload
        id="press_photo"
      />
      <img
        v-else
        :src="article ? require('@/assets/articles/default.png') : '#'"
        alt=""
        preload
        id="press_photo"
      />
    </div>
    <div class="caption">
      <h5>
        {{ article ? article.caption : "" }}
      </h5>
    </div>
    <div class="profile_section">
      <div class="tvf_profile">
        <a href="https://terravera.world" target="_blank">
          <div class="round_logo">
            <img
              src="@/components/articles/authors/terravera.png"
              alt="logo"
              id="symbol"
            />
          </div>
        </a>
        <div class="profile_info">
          <p>
            {{ article ? article.author_name : "" }} <br />
            {{ article ? article.date_day : "" }}
            {{ article ? article.date_month_str : "" }},
            {{ article ? article.date_year : "" }}
          </p>
        </div>
      </div>
      <a
        href="https://www.linkedin.com/company/terravera-foundation/about/?viewAsMember=true"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src="@/assets/8707.svg" alt="logo" id="linkedin_icon" />
      </a>
    </div>
    <div class="content_text" v-if="article" v-html="article.text"></div>
    <div class="related_section">
      <h2 id="related_header">Related</h2>
      <div class="related_articles">
        <div
          v-for="article in related_articles"
          :key="article.key"
          class="positions"
        >
          <ArticlePreview :article="article" class="article" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "../firebaseDb";
import ArticlePreview from "@/components/ArticlePreview.vue";

export default {
  name: "Article",
  components: {
    ArticlePreview,
  },
  updated() {
    window.scrollTo(0, 0);
  },
  watch: {
    $route: function() {
      window.scrollTo(0, 0);
      db.collection("articles")
        .where("url", "==", this.$route.params.url)
        .onSnapshot((snapshotChange) => {
          this.Articles = [];
          snapshotChange.forEach((doc) => {
            this.Articles.push({
              key: doc.id,
              title: doc.data().title,
              date: doc.data().date,
              date_day: doc.data().date_day,
              date_month_nr: doc.data().date_month_nr,
              date_month_str: doc.data().date_month_str,
              date_year: doc.data().date_year,
              author_name: doc.data().author_name,
              caption: doc.data().caption,
              intro: doc.data().intro,
              text: doc.data().text,
              url: doc.data().url,
            });
          });
          window.scrollTo(0, 0);
        });
      db.collection("articles")
        .where("url", "!=", this.$route.params.url)
        .onSnapshot((snapshotChange) => {
          this.related_articles = [];
          snapshotChange.forEach((doc) => {
            if (
              doc.data().published == true &&
              this.related_articles.length < 3
            ) {
              this.related_articles.push({
                key: doc.id,
                title: doc.data().title,
                date: doc.data().date,
                date_day: doc.data().date_day,
                date_month_nr: doc.data().date_month_nr,
                date_month_str: doc.data().date_month_str,
                date_year: doc.data().date_year,
                author_name: doc.data().author_name,
                caption: doc.data().caption,
                intro: doc.data().intro,
                text: doc.data().text,
                url: doc.data().url,
              });
            }
          });

          window.scrollTo(0, 0);
        });
    },
  },
  data() {
    return {
      Articles: [],
      related_articles: [],
    };
  },
  computed: {
    article: function() {
      return this.Articles[0];
    },
  },
  beforeCreate() {
    db.collection("articles")
      .where("url", "==", this.$route.params.url)
      .onSnapshot((snapshotChange) => {
        this.Articles = [];
        snapshotChange.forEach((doc) => {
          this.Articles.push({
            key: doc.id,
            title: doc.data().title,
            date: doc.data().date,
            date_day: doc.data().date_day,
            date_month_nr: doc.data().date_month_nr,
            date_month_str: doc.data().date_month_str,
            date_year: doc.data().date_year,
            author_name: doc.data().author_name,
            caption: doc.data().caption,
            intro: doc.data().intro,
            text: doc.data().text,
            url: doc.data().url,
          });
        });
      });
    db.collection("articles")
      .where("url", "!=", this.$route.params.url)
      .onSnapshot((snapshotChange) => {
        this.related_articles = [];
        snapshotChange.forEach((doc) => {
          if (
            doc.data().published == true &&
            this.related_articles.length < 3
          ) {
            this.related_articles.push({
              key: doc.id,
              title: doc.data().title,
              date: doc.data().date,
              date_day: doc.data().date_day,
              date_month_nr: doc.data().date_month_nr,
              date_month_str: doc.data().date_month_str,
              date_year: doc.data().date_year,
              author_name: doc.data().author_name,
              caption: doc.data().caption,
              intro: doc.data().intro,
              text: doc.data().text,
              url: doc.data().url,
            });
          }
        });
      });
  },
  methods: {
    imageExists(title) {
      try {
        const dummy_var = require("@/assets/articles/" + title + ".png");
        return true;
      } catch (e) {
        return false;
      }
    },
  },
};
</script>

<style scoped>
.article {
  min-height: 80vh;
}

.round_logo {
  margin-bottom: 10px;
}

.related_section {
  scroll-snap-align: start;
  padding-bottom: 10vh;
}

.related_articles {
  width: 80vw;
  transform: translateX(-15vw);
  margin-left: auto;
  margin-right: auto;
  display: grid;
  height: auto;
  grid-template-columns: 1fr 1fr 1fr;
}
.article {
  margin: 5px;
}

#related_header {
  transform: translateX(calc(-15vw + 5px));
  padding-top: 10vh;
}

@media only screen and (max-width: 900px) {
  .related_section {
    scroll-snap-align: none;
  }
  .related_articles {
    grid-template-columns: 1fr;
    scroll-snap-align: none;
    transform: none;
  }
  #related_header {
    transform: none;
  }
  .article {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-bottom: 10vh;
    margin-top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    scroll-snap-align: none;
  }
  #heading {
    font-size: 2.6rem;
  }
}
</style>
