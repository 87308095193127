<template>
  <div class="container">
    <h1 id="frontpage_header">
      News and <br />
      Updates <br />
      From <br />
      TERRAVERA <br />
    </h1>
    <h1 id="mobile_frontpage_header">
      News and Updates <br />
      From TERRAVERA <br />
    </h1>
    <p id="description">
      Latest updates, press releases <br />
      and posts from our Foundation <br />
    </p>
  </div>
</template>

<style scoped>
.container {
  display: flex;
  height: 70vh;
  justify-content: space-between;
}
#frontpage_header {
  margin-top: 15vh;
  padding: 0;
  margin-left: 10vw;
  line-height: 1.14;
  font-size: 3.5rem;
  font-weight: lighter;
}
#description {
  padding: 0;
  font-weight: 100;
  font-size: 1.6rem;
  margin-top: 15vh;
  margin-right: 10vw;
}
#mobile_frontpage_header {
  display: none;
}

@media only screen and (max-width: 900px) {
  .container {
    display: block;
  }
  #frontpage_header {
    display: none;
  }
  #mobile_frontpage_header {
    display: block;
    margin-top: 25vh;
    padding: 0;
    margin-left: 10vw;
    line-height: 1.14;
    font-size: 2rem;
    font-weight: lighter;
  }
  #description {
    padding: 0;
    font-weight: 100;
    font-size: 1.2rem;
    margin-top: 2vh;
    margin-left: 10vw;
  }
}
</style>

<script>
export default {
  name: "Frontpage",
};
</script>
