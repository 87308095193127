<template>
    <div>
        <div id="modal_content">
            <div class="modal_headers">
                <a href="https://terravera.world/#keyPoints" target="_blank" class="modal_header">Mission</a>
                <a href="https://terravera.world/#expandingList-What-We-Do" target="_blank" class="modal_header">What we do</a>
                <a href="https://terravera.world/#personCarousel" target="_blank" class="modal_header">About us</a>
                <a href="/" class="modal_header">News</a>
                <a href="mailto:tommy.olsen@terravera.world?subject=Contact Business Program"
                target="_blank" rel="noopener noreferrer" class="modal_header">Contact</a>
            </div>
            <div class="contact_section">
                <h3 id="oneliner">Make sustainability a reality</h3>
                <div class="icons">
                    <i class="fa" id="footer_linkedin"> <a href="https://www.linkedin.com/company/terravera-foundation/about/?viewAsMember=true" target="_blank">&#xf08c;</a> </i>
                    <i class="fa" id="mail"> <a href="mailto:info@terravera.world">&#xf0e0;</a></i>
                </div>
            </div>
            <i id="close_button" class="fa fa-times-circle" aria-hidden="true" @click="$emit('close')"></i>
        </div>
    </div>
</template>

<script>
export default {
    emits: ["close"]
}
</script>

<style scoped>
.fa-times-circle-o:before {
	content: '\00d7';
}

a {
    color: white;
    text-decoration: none;
}

#modal_content {
    color: white;
    animation: delay-appear 0.5s 0.5s;
    animation-fill-mode: both;
    z-index: 2;
    opacity: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 80vw;
    height: 100vh;
    margin-left: auto;
    margin-right: auto;
}

.modal_header {
    font-size: 3rem;
    margin-bottom: 1rem;
    margin-left: 0;
    display: block;
    color: white;
    text-decoration: none;
}

.contact_section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: bottom;
    margin-bottom: 20vh;
    margin-top: auto;
}

#oneliner {
   text-align: right;
   margin-top: auto;
}

.icons {
    font-size: 2rem;
    text-align: right;
}

#footer_linkedin {
    margin-left: 1rem;
}

#mail {
    margin-left: 1rem;
} 

#close_button {
    position: absolute;
    right: 5vw;
    top: 5vw;
    font-size: 40px;
    /* border: 2px white solid; */
    width: 40px;
    height: 40px;
    text-align: center;
    /* line-height: 40px;
    border-radius: 50%; */
}

#close_button:hover, .modal_header:hover, #footer_linkedin:hover, #mail:hover {
    transform: scale(1.1);
    transition-duration: 0.2s;
    opacity: 0.8;
    cursor: pointer;
}

    @keyframes delay-appear {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media only screen and (max-width: 900px) {
    #modal_content {
        color: white;
        animation: delay-appear 0.5s 0.5s;
        animation-fill-mode: both;
        z-index: 2;
        opacity: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 80vw;
        height: 100vh;
        margin-left: 10vw;
        margin-right: auto;
        padding-top: 5vh;
    }
    .modal_header {
        font-size: 2.2rem;
        margin-bottom: 0.5rem;
    }
    .contact_section {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: bottom;
        margin-top: 5vh;
    }

    #oneliner {
    font-size: 1rem;
    margin-bottom: 0;
    }
    .icons {
        font-size: 1.6rem;
        text-align: left;
    }

    #footer_linkedin {
        margin-left: 0;
    }

    #mail {
        margin-left: 1rem;
    } 
    }
</style>
