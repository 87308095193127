<template>
    <div class="navbar">
        <a href="http://terravera.world" target="_blank" rel="noopener noreferrer">
            <img src="@/assets/logo_symbol.png" alt="logo" id="navbar_logo">
        </a>  
        <!-- <a href="mailto:tommy.olsen@terravera.world?subject=Contact Business Program" target="_blank" rel="noopener noreferrer"> 
            <button id="subscribe" href="http://terravera.world">Contact</button>
        </a>  -->
        <p id="open_modal" @click="toggleModalState">&#9776;</p>
        <transition name="fade">
            <modal class="modal" v-if="modalOpen" @close="toggleModalState">
                <Modal />
            </modal>
        </transition>
    </div>
</template>

<script>
  import Modal from '@/components/Modal.vue';

  export default {
    name: 'Header',
    components: {
      Modal,
    },
    data() {
        return {
            modalOpen: false
        }
    },
    methods: {
        toggleModalState() {
        this.modalOpen = !this.modalOpen;
        }
    }
  };
</script>

<style scoped>
    .navbar {
        scroll-snap-align: start;
        height: 30vh;
    }
    #navbar_logo {
        position: absolute;
        top: 10vw;
        left: 10vw;
        padding: 0;
        min-height: 50px;
        /* min-width: 150px;
        min-height: 50.8px;
        max-width: 195px;
        max-height: 66px; */
        height: 4vw;
    }

    #navbar_logo:hover, #footer_linkedin:hover, #mail:hover, #privacy:hover,
    #footer_logo:hover, #linkedin_icon:hover  {
        transform: scale(1.1);
        transition-duration: 0.2s;
        opacity: 0.8;
        cursor: pointer;
    }

    #open_modal {
        position: absolute;
        top: 9vw;
        margin:0;
        right: 10vw;
        border: none;
        color: #191a1e;
        font-size: 3rem;
        min-height: 50px;
        height: 4vw;
    }

    #open_modal:hover {
        transform: scale(1.1);
        transition-duration: 0.2s;
        opacity: 0.8;
        cursor: pointer;
    }

    @media only screen and (min-width: 1400px) {
        #subscribe {
            font-size: 20px;
        }
    }

    @media only screen and (max-width: 1100px) {
        .navbar {
            scroll-snap-align: none;
            height: 15vh;
        }
        #navbar_logo {
            min-height: 40px;
        }
        #open_modal {
            top: calc(10vw - 1rem);
            height: 4vw;
            margin:0;
            right: 10vw;
            border: none;
            color: #191a1e;
            font-size: 3rem;
        }
    }

    .modal {
        height: 100%;
        width: 100%;
        position: fixed; /* Stay in place */
        z-index: 1; /* Sit on top */
        right: 0;
        top: 0;
        background-color: #18332f; /* Black fallback color */
        overflow: hidden;
    }

.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}


@keyframes enter {
  0% {
    opacity: 0%;
    width: 0%;
  }
  100% {
    opacity: 100%;
    width: 100%;
  }
}

@keyframes leave {
  0% {
    width: 100%;
    opacity: 100%;
  }
  100% {
    width: 0%;
    opacity: 0%;
  }
}

    /* @keyframes modal-animation {
    0% {
        
    }
    100% {
        background-color: rgb(0,0,0);
        ; width: 100%;
    }
    } */

    
</style>