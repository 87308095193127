<template>
  <div class="home">
    <Frontpage />
    <Articles />
  </div>
</template>

<script>
import Articles from '../components/Articles.vue'
// @ is an alias to /src
import Frontpage from '../components/Frontpage.vue'

export default {
  name: 'Home',
  components: {
    Articles,
    Frontpage,
  }
}
</script>

<style scoped>
.home{
  min-height: 80vh;
}
</style>
