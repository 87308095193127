import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home'
import Article from '../views/Article'
import UnpublishedArticles from '../views/UnpublishedArticles'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/NorCod%20med%20i%20TERRAVERA%20Business%20Program',
    redirect: {
      path: '/NorCod-med-i-TERRAVERA-Business-Program',
    }
  },
  {
    path: '/:url',
    name: 'Article',
    component: Article
  },
  {
    path: '/dev',
    name: 'UnpublishedArticle',
    component: UnpublishedArticles
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


export default router