import firebase from 'firebase/app';

import 'firebase/auth';        // for authentication
import 'firebase/storage';     // for storage
import 'firebase/database';    // for realtime database
import 'firebase/firestore';   // for cloud firestore
import 'firebase/messaging';   // for cloud messaging
import 'firebase/functions';   // for cloud functions

  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  var firebaseConfig = {
    apiKey: "AIzaSyDjSOG8-jH4XnsuhQXaa9HHmotC4ROn0qk",
    authDomain: "tvf-news-4faa9.firebaseapp.com",
    projectId: "tvf-news-4faa9",
    storageBucket: "tvf-news-4faa9.appspot.com",
    messagingSenderId: "69189753067",
    appId: "1:69189753067:web:4b51f52af83399d16c86ae",
    measurementId: "G-2165JFNJ65"
  };
  // Initialize Firebase
 
const firebaseApp = firebase.initializeApp(firebaseConfig);
// firebase.analytics();

export const db = firebaseApp.firestore();