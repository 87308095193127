<template>
  <div class="articles">
    <div
      v-for="article in publishedArticles"
      :key="article.key"
      class="positions"
    >
      <ArticlePreview :article="article" class="article" />
    </div>
  </div>
</template>

<script>
import { db } from "../firebaseDb";
import ArticlePreview from "@/components/ArticlePreview.vue";

export default {
  name: "Articles",
  components: {
    ArticlePreview,
  },
  data() {
    return {
      Articles: [],
    };
  },
  computed: {
    publishedArticles: function() {
      return this.Articles.filter((a) => a.published == true);
    },
  },
  created() {
    db.collection("articles")
      .orderBy("date", "desc")
      .onSnapshot((snapshotChange) => {
        this.Articles = [];
        snapshotChange.forEach((doc) => {
          this.Articles.push({
            key: doc.id,
            title: doc.data().title,
            date: doc.data().date,
            date_day: doc.data().date_day,
            date_month_nr: doc.data().date_month_nr,
            date_month_str: doc.data().date_month_str,
            date_year: doc.data().date_year,
            author_name: doc.data().author_name,
            caption: doc.data().caption,
            intro: doc.data().intro,
            text: doc.data().text,
            url: doc.data().url,
            published: doc.data().published,
          });
        });
      });
  },
};
</script>

<style scoped>
.articles {
  scroll-snap-align: start;
  width: 80vw;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  height: auto;
  grid-template-columns: 1fr 1fr 1fr;
  padding-top: 10vh;
  margin-bottom: 30vh;
}
.article {
  margin: 5px;
}

@media only screen and (max-width: 1100px) {
  .articles {
    width: 80vw;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    height: auto;
    grid-template-columns: 1fr;
    margin-bottom: 10vh;
    scroll-snap-align: none;
  }
  .article {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-bottom: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
    scroll-snap-align: none;
  }
}
</style>
