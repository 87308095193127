<template>
    <footer>
        <a href="https://terravera.world" target="_blank">
            <img src="@/assets/tvf_logo_white_t.png" id="footer_logo" />
        </a>
        <p id="copyright"> © 2020 TERRAVERA&trade;. All Rights Reserved.</p>
        <i class="fa" id="footer_linkedin"> <a href="https://www.linkedin.com/company/terravera-foundation/about/?viewAsMember=true" target="_blank">&#xf08c;</a> </i>
        <i class="fa" id="mail"> <a href="mailto:info@terravera.world">&#xf0e0;</a></i>
        <p id="privacy"> <a href="https://terravera.world/privacy-policy" target="_blank"> Privacy Policy </a></p>
        <p id="footer_info"> TERRAVERA Foundation (NO 824 429 812) · Post: Laguneveien 7, 5239 Rådal</p>
    </footer>
</template>

<style scoped>
footer {
    background: #18332f;
    height: 30vh;
    display: flex;
    color: white;
}

a {
    color: white;
    text-decoration: none;
}

#footer_logo {
    margin-top: 8vh;
    margin-left: 4vw;
    width: 10vw;
    min-width: 150px;
    min-height: 50.8px;
    max-width: 195px;
    max-height: 66px;
    height: auto;
    display: block;
}

#copyright {
    margin-top: 21vh;
    margin-left: 4vw;
    position: absolute;
    font-size: 16px;
}

#footer_linkedin {
    font-size: 35px;
    margin-top: 9.1vh;
    margin-left: -moz-calc(96vw - 80px);
    margin-left: -webkit-calc(96vw - 80px);
    margin-left: -o-calc(96vw - 80px);
    margin-left: calc(96vw - 80px);
    position: absolute; 
}

#mail {
    font-size: 35px;
    margin-top: 9vh;
    margin-left: -moz-calc(96vw - 30px);
    margin-left: -webkit-calc(96vw - 30px);
    margin-left: -o-calc(96vw - 30px);
    margin-left: calc(96vw - 30px);
    position: absolute; 
} 

#privacy {
    font-size: 16px;
    margin-top: 21vh;
    margin-left: -moz-calc(96vw - 90px);
    margin-left: -webkit-calc(96vw - 90px);
    margin-left: -o-calc(96vw - 90px);
    margin-left: calc(96vw - 90px);
    position: absolute; 
}

#footer_info {
    position: absolute;
    margin-left: -moz-calc(50vw - 139px);
    margin-left: -webkit-calc(50vw - 139px);
    margin-left: -o-calc(50vw - 139px);
    margin-left: calc(50vw - 139px);
    font-size: 8px;
    margin-top: 22vh;
    text-align: center;
}

#navbar_logo:hover, #footer_linkedin:hover, #mail:hover, #privacy:hover,
 #footer_logo:hover, #linkedin_icon:hover  {
    transform: scale(1.1);
    transition-duration: 0.2s;
    opacity: 0.8;
    cursor: pointer;
}

@media only screen and (min-width: 1400px) {
        #footer_info {
            font-size: 12px;
            margin-left: -moz-calc(50vw - 208px);
            margin-left: -webkit-calc(50vw - 208px);
            margin-left: -o-calc(50vw - 208px);
            margin-left: calc(50vw - 208px);
        }
    }

@media only screen and (max-width: 1100px) {
    #footer_info {
    margin-top: 26vh;
    }
    #privacy, #copyright {
        font-size: 10px;
    }
    #privacy {
        margin-left: -moz-calc(96vw - 60.4px);
        margin-left: -webkit-calc(96vw - 60.4px);
        margin-left: -o-calc(96vw - 60.4px);
        margin-left: calc(96vw - 60.4px);
    }
    #mail {
        font-size: 25px;
        margin-left: -moz-calc(96vw - 25px);
        margin-left: -webkit-calc(96vw - 25px);
        margin-left: -o-calc(96vw - 25px);
        margin-left: calc(96vw - 25px);
    }
    #footer_linkedin {
        font-size: 25px;
        margin-left: -moz-calc(96vw - 60px);
        margin-left: -webkit-calc(96vw - 60px);
        margin-left: -o-calc(96vw - 60px);
        margin-left: calc(96vw - 60px);
    }
    #footer_logo {
        max-width: 84px;
        max-height: 28.4px;
        min-width: 84px;
        min-height: 28.4px;
    }
}
</style>