<template>
  <div class="p">
    <router-link :to="{ name: 'Article', params: { url: article.url } }">
      <video class="preview_video" playsinline autoplay loop muted>
        <source
          v-if="previewExists(article.title)"
          v-bind:src="require('@/assets/previews/' + article.title + '.mp4')"
          type="video/mp4"
        />
        <source
          v-else
          v-bind:src="require('@/assets/previews/default.mp4')"
          type="video/mp4"
        />
        <!-- <source v-bind:src=" (windowHeight > 500 )? require('@/assets/previews/' + article.title +'.mp4') : '#'" type="video/mp4"> -->
        <!-- :src=" screen.width > 900px ? require('@/assets/previews/' + article.title +'.mp4') : '#'" -->
      </video>
      <!-- <video class="preview_video" v-bind:src="require('@/assets/previews/' + article.title +'.mp4')"
            type='video/mp4' autoplay playsinline muted loop /> -->
      <p class="date">
        {{ article.date_month_str }} {{ article.date_day }},
        {{ article.date_year }}
      </p>
      <h3 class="title">{{ article.title }}</h3>
    </router-link>
  </div>
</template>

<script>
import "fs";

export default {
  name: "ArticlePreview",
  props: {
    article: Object,
  },
  data() {
    return {
      windowHeight: window.innerHeight,
    };
  },
  watch: {
    windowHeight(newHeight, oldHeight) {
      this.txt = `it changed to ${newHeight} from ${oldHeight}`;
    },
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  methods: {
    onResize() {
      this.windowHeight = window.innerHeight;
    },
    goToArticle(article_url) {
      this.$router.push({ name: "/", params: { url: article_url } });
    },
    previewExists(title) {
      try {
        const dummy_var = require("@/assets/previews/" + title + ".mp4");
        return true;
      } catch (e) {
        return false;
      }
    },
  },
};
</script>

<style scoped>
video::-webkit-media-controls {
  display: none;
}
video::-webkit-media-controls-start-playback-button {
  display: none;
}
.preview_video {
  width: 100%;
}

a {
  color: #18332f;
  text-decoration: none;
}

.title {
  margin-top: 0px;
}

.date {
  margin: 0px;
  color: #888;
  font-size: 1rem;
}
</style>
